<!--
 * @Descripttion: teacher.dingdingkaike
 * @version: 1.1pcMenu
 * @Author: sueRimn
 * @Date: 2019-11-05 17:21:49
 * @LastEditors: sueRimn
 * @LastEditTime: 2019-12-12 11:59:26
 -->
<template>
  <div class="school-wrap">
    <myHeader :location="true" :status="1"></myHeader>
    <div class="functional-area">
      <div class="area_left">
        <div class="title2">
          {{
            Number(userInfo.status_type) == 2
              ? '您的网校已停用！'
              : '数据清理通知！'
          }}
        </div>
        <div style="text-align: center;">
          <div class="tip">
            {{
              Number(userInfo.status_type) == 2
                ? '您的网校已停用，网校内所有角色的账号均无法登录，如有疑问，请联系客户经理！'
                : '根据我国相关的信息安全与数据处理要求，由于您的网校已停用超过30天，系统视为您已停止使用我方服务，系统已自动清理网校数据；从2021年5月起，系统已通过短信的方式进行通知，在您收到通知后，如未及时处理导致数据被清理，由您自行承担，为此带来不便，敬请谅解，如有疑问请联系客户经理。'
            }}
          </div>
          <!-- <div class="tip">
            2.
            网校停用后，已产生的数据不会立刻清理，当账号停用已超过30天，会自动清理已产生的数据，无法恢复！
          </div>
          <div class="tip">
            3. 未登录超过30天，自动停用网校；停用超过30天，自动清理已有数据！
          </div> -->
        </div>
        <div style="text-align: center">
          <el-button
            plain
            class="btn"
            style="
              margin-right: 20px;
              width: 126px;
              color: #0aa29b;
              border-color: #0aa29b;
            "
            @click.stop="switchSchool"
          >
            切换网校
          </el-button>
          <el-button type="primary" class="btn" @click="dialogVisible2 = true">
            联系客户经理
          </el-button>
        </div>
        <!-- img_qlzt@2x -->
        <img
          :src="
            Number(userInfo.status_type) == 2
              ? require('@ass/img/1.4.2.2/img_tyzt@2x.png')
              : require('@ass/img/1.4.2.2/img_qlzt@2x.png')
          "
          style="width: 262px; margin: 0 auto"
          alt=""
        />
        <!-- <div>联系客户经理</div> -->
      </div>
      <div class="area_right">
        <div v-if="jgInfo.activity.length > 0">
          <div
            v-if="jgInfo.activity[0].src"
            style="width: 258px; height: 194px"
            class="mb20"
          >
            <div v-if="jgInfo.activity.length > 1">
              <el-carousel
                class="mb20 cp"
                height="194px"
                ref="carousel"
                @click.native="linkTo"
              >
                <el-carousel-item
                  v-for="(item2, index2) in jgInfo.activity"
                  :key="index2"
                >
                  <img width="258" height="194" :src="item2.src" alt="" />
                </el-carousel-item>
              </el-carousel>
            </div>
            <div v-else class="mb20 cp" @click="linkTo">
              <img
                width="258"
                height="194"
                :src="jgInfo.activity[0].src"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="product-trends">
          <div class="title">产品动态</div>
          <ul>
            <li
              v-for="(item3, index3) in jgInfo.systemMsg"
              :key="index3"
              @click="producttrends_dialog(index3, item3)"
            >
              <div>{{ item3.title | ellipsis(13) }}</div>
              <div>{{ item3.c_time | formatTimeStamp('yyyy-MM-dd ') }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 联系客服经理 -->
    <el-dialog
      class="dialogVisible2"
      :visible.sync="dialogVisible2"
      width="500px"
    >
      <div class="main_contain">
        <div class="titlenn">联系客户经理</div>
        <div class="tu">
          <img src="~@ass/img/1.4.0/img_kfewm@2x.png" alt="" />
        </div>
        <div class="tip">微信扫码添加客户经理微信</div>
      </div>
    </el-dialog>
    <!-- 产品动态弹窗 -->
    <el-dialog
      class="dialogVisible"
      :visible.sync="dialogVisible"
      width="568px"
    >
      <div slot="title">
        <div class="dialog_font1">{{ dialogVisible_value.title }}</div>
        <div class="dialog_font2">
          发布日期：
          {{ dialogVisible_value.c_time | formatTimeStamp('yyyy-MM-dd ') }}
        </div>
      </div>
      <div class="contenttt">
        <div
          class="dialogVisible_container"
          v-html="dialogVisible_value.text"
        ></div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import bg from '@/mixin/background'
import myHeader from '@/components/Header'

export default {
  name: 'organizationDisable',

  mixins: [bg],

  data() {
    return {
      dialogVisible2: false,

      dialogVisible: false,

      dialogVisible_value: [],

      acitving: 0,

      jgInfo: {
        business: '',
        earnings: '',
        jg_logo: '',
        title: '',
        teacher: {},
        activity: [{ url: '', src: '' }],
        today_order_num: 0,
        today_total_income: 0,
        remainingTime: [],
      },
    }
  },

  filters: {
    ellipsis(value, len) {
      if (!value) return ''
      if (value.length > len) {
        return value.slice(0, len) + '...'
      }
      return value
    },
  },

  components: {
    myHeader,
  },

  computed: {
    ...mapState(['userInfo']),
  },

  created() {
    this.getJiGouInformation()
  },

  methods: {
    // 切换网校
    switchSchool() {
      this.$router.push({
        path: '/switchSchool',
      })
    },

    //产品动态弹窗
    producttrends_dialog(val, val2) {
      this.acitving = val + 1
      this.dialogVisible_value = val2
      this.dialogVisible = true
    },
    // 获取机构的一些信息
    getJiGouInformation() {
      this.$http({
        name: 'jigouInformation',
        url: '/User/jigouInformation',
        callback: ({ data }) => {
          if (data.teacher) {
            this.$router.replace({
              path: '/class',
            })
            return
          }
          this.jgInfo = Object.assign({}, this.jgInfo, data)
        },
      })
    },
    //图像跳转
    linkTo() {
      if (this.jgInfo.activity.length > 1) {
        let activeIndex = this.$refs.carousel.activeIndex
        let url = this.jgInfo.activity[activeIndex].url
        this.$store.dispatch('open', url)
      } else {
        const url2 = this.jgInfo.activity[0].url
        this.$store.dispatch('open', url2)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .dialogVisible2 {
  .el-dialog {
    border-radius: 10px;
  }
}
.main_contain {
  .titlenn {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 18px;
    text-align: center;
  }
  .tu {
    width: 222px;
    height: 222px;
    margin: 34px auto 43px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .tip {
    font-size: 14px;
    color: #666666;
    line-height: 14px;
    text-align: center;
    margin-bottom: 52px;
  }
}
.school-wrap {
  min-width: 860px;
  background: #f5f5f5;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
  .functional-area {
    height: calc(100% - 92px);
    display: flex;
    padding: 20px;
    justify-content: space-between;
    .area_left {
      flex: 1;
      padding: 20px;
      background-color: #fff;
      .title2 {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        margin-bottom: 20px;
      }
      .btn {
        margin: 30px auto 53px;
      }
      .tip {
        font-size: 14px;
        color: #666666;
        line-height: 24px;
      }
    }
    .area_right {
      margin-left: 20px;
      width: 258px;
      .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 150px;
        margin: 0;
      }
      .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
      }

      .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
      }
      .speed-channel {
        background-color: #fff;
        margin-top: 20px;
        padding: 20px;
        ul {
          li {
            margin-top: 20px;
          }
        }
        .des {
          margin-left: 14px;
          .name {
            margin-bottom: 10px;
            font-size: 15px;
            color: #333333;
            line-height: 20px;
          }
          .introduce {
            font-size: 12px;
            color: #999999;
            line-height: 16px;
          }
        }
        .tu {
          height: 60px;
          width: 60px;
          img {
            height: 60px;
            width: 60px;
          }
        }
      }
      .product-trends {
        box-sizing: border-box;
        padding: 20px;
        overflow: auto;
        width: 258px;
        height: 222px;
        background-color: #fff;
        ul {
          width: 100%;
          font-size: 12px;
          color: #999999;
          line-height: 13px;
          li {
            cursor: pointer;
            height: 12px;
            width: 100%;
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            div {
              float: left;
            }
            div:first-child {
              color: #666666;
              font-size: 13px;
              margin-right: 2px;
              width: 149px;
            }
            div:first-child:hover {
              color: #1b9d97;
            }
          }
          li:hover {
            color: #1b9d97;
          }
        }
      }
    }
  }
}
.dialogVisible {
  ::v-deep .el-dialog__body {
    padding: 20px;
  }
  ::v-deep .el-dialog {
    height: 428px;
  }
  ::v-deep .el-dialog__header {
    padding: 14px 20px;
    border-bottom: 1px solid #f5f5f5;
  }
}

.contenttt {
  font-size: 12px;
  color: #333333;
  line-height: 16px;
  overflow: auto;
  .dialogVisible_container {
    height: 308px;
  }
  ul {
    height: 308px;
    li {
      height: 20px;
      margin-top: 20px;
    }
  }
}
.dialog_font1 {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
  line-height: 15px;
}
.dialog_font2 {
  margin-top: 10px;
  font-size: 12px;
  color: #999999;
  line-height: 12px;
}
::-webkit-scrollbar {
  display: none;
}
.title {
  font-size: 16px;
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
  line-height: 21px;
  display: inline;
}
::v-deep .el-carousel__button {
  height: 8px;
  width: 8px;
  border-radius: 50%;
}
</style>
